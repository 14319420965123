import { Injectable } from '@angular/core';
import * as pdfmake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

@Injectable({
  providedIn: 'root'
})
export class PDFService {
    constructor(){
        pdfmake.vfs = pdfFonts.pdfMake.vfs;
    }
    

  
  public downloadPDF(data: any) {


    pdfmake.createPdf(data).print();

   let dd = {
    "content": [
        {
            "text": "AGREEMENT TO MODIFY\n\n",
            "style": "header",
            "alignment": "center"
        },
        {
            "table": {
                "widths": [
                    "auto",
                    "*",
                    "auto",
                    "*"
                ],
                "body": [
                    [
                        "Buyer: ",
                        "{{companyAddress}}\n{{cityName}},{{stateName}} {{zipName}}",
                        "Seller: ",
                        "Hollingbery & Son, Inc.\n302 N. 1st Ave.\nYakima, WA 98902"
                    ]
                ]
            }
        },
        {
            "text": [
                "\nBuyer and Seller hereby agree to modify the Hop Sales Offer No.{{offerNumber}}:\n\n"
            ]
        },
        {
            "alignment": "center",
            "table": {
                "widths": [
                    "auto",
                    "*",
                    "*"
                ],
                "body": [
                    [
                        {
                            "text": "Product",
                            "bold": true,
                            "decoration": "underline"
                        },
                        {
                            "text": "Previous",
                            "bold": true,
                            "decoration": "underline"
                        },
                        {
                            "text": "Amended",
                            "bold": true,
                            "decoration": "underline"
                        }
                    ],
                    [
                        "{{lineItemsAmended}}",
                        "",
                        ""
                    ]
                ]
            }
        },
        "\nAll other terms and conditions shall remain the same. Seller’s offer to sell the Hops to Buyer under the terms of this Agreement to Modify shall expire at 5 p.m. on {{expirationDate}}, unless Buyer signs this Agreement to Modify and provides it to Seller by such time and date. Seller shall provide a signed copy of this Agreement to Modify by E-mail at the E-mail address provided below for Buyer.\n\n{{otherRemarks}}\n\n",
        {
            "fontSize": 10,
            "table": {
                "widths": [
                    "*",
                    "*"
                ],
                "body": [
                    [
                        {
                            "text": "Buyer:",
                            "bold": "true"
                        },
                        {
                            "text": "Seller:",
                            "bold": "true"
                        }
                    ],
                    [
                        {
                            "text": "{{companyName}}\nE-mail: {{email}}"
                        },
                        {
                            "text": "Hollingbery & Son, Inc.\nE-mail: sales@hollingberyandson.com"
                        }
                    ],
                    [
                        {
                            "text": [
                                {
                                    "text": "\n\n_________________________________________\n",
                                    "bold": "true",
                                    "alignment": "center"
                                },
                                {
                                    "text": "Signature",
                                    "bold": "true",
                                    "alignment": "center"
                                },
                                {
                                    "text": "\n\n_________________________________________\n",
                                    "bold": "true",
                                    "alignment": "center"
                                },
                                {
                                    "text": "Date",
                                    "bold": "true",
                                    "alignment": "center"
                                }
                            ]
                        },
                        {
                            "text": [
                                {
                                    "text": "\n\n_________________________________________\n",
                                    "bold": "true",
                                    "alignment": "center"
                                },
                                {
                                    "text": "Signature",
                                    "bold": "true",
                                    "alignment": "center"
                                },
                                {
                                    "text": "\n\n_________________________________________\n",
                                    "bold": "true",
                                    "alignment": "center"
                                },
                                {
                                    "text": "Date",
                                    "bold": "true",
                                    "alignment": "center"
                                }
                            ]
                        }
                    ]
                ]
            }
        }
    ],
    "styles": {
        "marginSm": {
            "margin": [
                5,
                0
            ]
        },
        "indent": {
            "margin": [
                30,
                0
            ]
        },
        "header": {
            "fontSize": 14,
            "bold": true
        },
        "subheader": {
            "fontSize": 14,
            "bold": true
        },
        "quote": {
            "italics": true
        },
        "small": {
            "fontSize": 8
        },
        "table": {
            "margin": [
                0,
                5,
                0,
                0
            ]
        }
    }
}

    // console.log(data)
    
    // console.log(JSON.stringify(dd))
    // dd.footer = function(currentPage, pageCount) {return {text: `Page ${currentPage.toString()} of ${pageCount.toString()}`,alignment: 'center',margin: [0, 10]};},
    // pdfmake.createPdf(dd).print();

    // data.footer = function(currentPage, pageCount) {return {text: `Page ${currentPage.toString()} of ${pageCount.toString()}`,alignment: 'center',margin: [0, 10]};},



  }

public generateVerbiage(data, offerNumber){

    let lineItems =""
    let amendedLineItems = ""
    let totalPrice = 0
    let tenPercent = 0
    let currency = ""
    if(data.LineItems.length > 0 && Object.keys(data.LineItems[0]).length > 1){
        data.LineItems.map(item => {
            var temp = [
                // item.varietyObj.name, //+ " - " +item.inventoryItem.name,
                item.inventoryItem.name,
                item.inventoryItem.description,
                // item.cropYear,
                item.quantity,
                item.unitOfMeasure,
                (item.price.toLocaleString('en-US', { style: 'currency', currency: item.currency })),
                item.currency,
                // item.packageObj.name,
            ]
            // console.log(temp)
            lineItems += JSON.stringify(temp) + ","
            if(item.priceAmend){
                totalPrice += item.priceAmend * item.quantityAmend
            } else {
                totalPrice += item.price * item.quantity
            }
            currency = item.currency
        })
        lineItems = lineItems.slice(0, -1)
        tenPercent = totalPrice * .1
    }
    if(data.LineItemsAmended && Object.keys(data.LineItemsAmended[0]).length > 1){
        data.LineItemsAmended.map((item, idx) => {
            var temp = [
                data.LineItems[idx].inventoryItem.name + " - " + data.LineItems[idx].inventoryItem.description,
                data.LineItems[idx].quantity + " - " + data.LineItems[idx].price.toLocaleString('en-US', { style: 'currency', currency: item.currency }),
                item.quantityAmend + " - " + item.priceAmend.toLocaleString('en-US', { style: 'currency', currency: item.currency })
            ]
            // console.log(temp)
            amendedLineItems += JSON.stringify(temp) + ","
        })
        amendedLineItems = amendedLineItems.slice(0, -1)
    }


    let payload = data.Verbiage
    let offerId = offerNumber || this.getJulianDate() + data.LastId;

    let newPayload = payload.replace(/{{totalPrice}}|{{tenPercent}}|{{currentDate}}|{{companyName}}|{{companyAddress}}|{{cityName}}|{{stateName}}|{{zipName}}|{{contractYear}}|{{expirationDate}}|{{phone}}|{{email}}|{{effectiveDate}}|\["{{lineItems}}","","","","",""\]|{{faxNumber}}|{{otherRemarks}}|{{offerNumber}}|\["{{lineItemsAmended}}","",""\]/gi, function(match) {
        if (match === '{{currentDate}}') return data.CurrentDate || "";
        if (match === '{{companyName}}') return data.Address.company || "";
        if (match === '{{companyAddress}}') return data.Address.address || "";
        if (match === '{{cityName}}') return data.Address.city || "";
        if (match === '{{stateName}}') return data.Address.state || "";
        if (match === '{{zipName}}') return data.Address.zip || "";
        if (match === '{{expirationDate}}') return data.ExpirationDate || "";
        if (match === '{{effectiveDate}}') return data.EffectiveDate || "";
        if (match === '{{contractYear}}') return data.ContractYear || "";
        if (match === '\["{{lineItems}}","","","","",""\]') return lineItems || "";
        if (match === '{{phone}}') return data.Address.phoneNumber || "";
        if (match === '{{email}}') return data.Address.email || "";
        if (match === '{{faxNumber}}') return  data.Address.faxNumber || "";
        if (match === '{{offerNumber}}') return  offerId || "";
        if (match === '{{otherRemarks}}') return  data.OtherRemarks || "";
        if (match === '\["{{lineItemsAmended}}","",""\]') return amendedLineItems || "";
        if (match === '{{totalPrice}}') return  totalPrice.toLocaleString('en-US', { style: 'currency', currency: currency }) || "";
        if (match === '{{tenPercent}}') return  tenPercent.toLocaleString('en-US', { style: 'currency', currency: currency }) || "";


        });
        let finalPayload = JSON.parse(newPayload)
        finalPayload.footer = function(currentPage, pageCount) {return {
            columns: [
                {text: 'Initial_______', alignment: 'left', fontSize: 10, margin: [40, 10, 0, 0]}, 
                {text: `Page ${currentPage.toString()} of ${pageCount.toString()}`,alignment: 'center',fontSize: 10,margin: [0, 10, 40, 0]},
                {text: data.Address.company, alignment: 'right',fontSize: 10, margin: [0, 10, 40, 0]}
              ]
        }}
        return finalPayload;
    }
    public openPDF(data, companyName){
         data.footer = function(currentPage, pageCount) {return {
            columns: [
                {text: 'Initial_______', alignment: 'left', fontSize: 10, margin: [40, 10, 0, 0]}, 
                {text: `Page ${currentPage.toString()} of ${pageCount.toString()}`,alignment: 'center',fontSize: 10,margin: [0, 10, 40, 0]},
                {text: companyName, alignment: 'right',fontSize: 10, margin: [0, 10, 40, 0]}
              ]
        }}
        pdfmake.createPdf(data).open();
    }

    getJulianDate(){
        const today = new Date();
        const start = new Date(today.getFullYear(), 0, 0);
        const diff = +today - +start + (start.getTimezoneOffset() - today.getTimezoneOffset()) * 60 * 1000;
        const oneDay = 1000 * 60 * 60 * 24;
        var julian = Math.floor(diff / oneDay); // + 1
        var result  = julian < 100 ? "0" + (julian < 10 ? "0" + julian : julian) : julian;

        const year = today.getFullYear().toString().substr(-2);

        // var largestId = this.dataSource.data.reduce((max, obj) => Math.max(max, obj.id), 0);
        // largestId++
        return result.toString() + year;
  }
    

}

