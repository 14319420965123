import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator, MatSort } from "@angular/material";
import {
  faBan,
  faCheck,
  faPen,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faEdit, faPhone } from "@fortawesome/pro-duotone-svg-icons";
import { ErrorService } from "../../../../../@core/utils/error.service";
import { Carrier } from "../../../../modules/pick-plan/models/carrier";
import { TruckType } from "../../../../modules/pick-plan/models/truck-type";
import { carrierService } from "../../../../modules/pick-plan/services/carrier.service";
import { truckTypeService } from "../../../../modules/pick-plan/services/truck-type.service";
import { PlatformConfigService } from "../../../services/platform-config.service";
import { DispatchEditComponent } from "../dispatch-edit/dispatch-edit.component";
import * as _ from "underscore";
import { CarrierEditListComponent } from "../carrier-edit-list/carrier-edit-list.component";
import { MatDialog } from "@angular/material/dialog";
import { SessionService } from "../../../../../@core/utils/session.service";
import {
  ColumnModel,
  FilterSettingsModel,
  GridComponent,
  PageSettingsModel,
} from "@syncfusion/ej2-angular-grids";
import {
  ConfirmationData,
  ConfirmationDialogComponent,
} from "../../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component";
import { NbToastrService } from "@nebular/theme";
import { Observable } from "rxjs";
import { KmlService } from "../../../../../@core/utils/kml.service";

@Component({
  selector: "carrier-list-table",
  templateUrl: "./carrier-list-table.component.html",
  styleUrls: ["./carrier-list-table.component.css"],
})
export class CarrierListTableComponent implements OnInit {
  //#region Properties
  public gridName: string = "CarrierConfigGrid";
  public editActive = false;
  public enablePersistence: boolean = true;
  public carrierId: number;
  public carrierLoading = true;
  public submitting: any;
  public editCarrier: Carrier = {};
  public truckTypes: TruckType[] = [];
  public editTruck: TruckType = {};
  public dispatchPhone: string = "Loading...";
  public pageSettings: PageSettingsModel = {
    pageSize: 50,
    pageSizes: [50, 100, 200],
  };
  public filterSettings: FilterSettingsModel = {
    mode: "Immediate",
    type: "Excel",
  };
  public toolbarOptions: string[] = [
    "ExcelExport",
    "KML Export",
    "JSON Export",
  ];
  public allColumns: ColumnModel[] = [];
  public dataSource = { data: [] };
  public allCarriers: Carrier[] = [];
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    "name",
    "description",
    "truckType",
    "phoneNumber",
    "email",
    "integrationId",
    "isActive",
    "edit",
  ];
  //#endregion

  //#region Icon Properties
  public faPlus = faPlus;
  public faEdit = faEdit;
  public faPen = faPen;
  public faTrash = faTrash;
  public faTimes = faTimes;
  public faCheck = faCheck;
  public faDuePhone = faPhone;
  public faBan = faBan;
  //#endregion

  //#region ViewChild Properties
  @ViewChild("grid")
  grid: GridComponent;

  @ViewChild("carrierEditTemplate")
  public carrierEditTemplate: any;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;
  //#endregion

  //#region Constructor with Dependencies
  constructor(
    private errorService: ErrorService,
    public carrierService: carrierService,
    public truckTypeService: truckTypeService,
    private dialogService: MatDialog,
    private platformConfigService: PlatformConfigService,
    private sessionService: SessionService,
    private kmlService: KmlService,
    private toastrService: NbToastrService
  ) {}
  //#endregion

  ngOnInit() {
    this.loadData();
    this.setColumns();
  }

  loadData() {
    setTimeout(() => {
      this.carrierLoading = true;

      Observable.forkJoin([this.carrierService.getCarriers()]).subscribe(
        (x) => {
          this.allCarriers = x[0];

          this.carrierLoading = false;
          this.setColumns();
        }
      ),
        (err) => {
          this.errorService.handleError(err);
          this.carrierLoading = false;
        };
      this.platformConfigService.getDispatchPhone().subscribe((phone) => {
        this.dispatchPhone = phone;
      });

      // this.truckTypeService.getTruckTypes().subscribe(
      //   (data) => {
      //     this.truckTypes = data;
      //     if (this.paginator)
      //       this.paginator._changePageSize(this.paginator.pageSize);
      //     this.carrierLoading = false;
      //   },
      //   (err) => {
      //     this.errorService.handleError(err);
      //     this.carrierLoading = false;
      //   }
      // );
    }, 1000);
  }

  toolbarClick(args) {
    this.carrierLoading = true;
    this.grid.aggregates = [];
    this.grid.refresh();

    if (args.item.properties.text == "KML Export") {
      this.kmlService.getAllKML(new Date().getFullYear());
      this.carrierLoading = false;
    } else if (args.item.properties.text == "JSON Export") {
      this.kmlService.getGeoJSONPolygons();
      this.carrierLoading = false;
    } else {
      this.grid
        .excelExport({
          fileName: `CarrierExport.xlsx`,
        })
        .then(() => {
          this.carrierLoading = false;
        });
    }
  }

  setColumns() {
    this.allColumns = [];

    this.allColumns.push(
      ...[
        {
          field: "name",
          headerText: "Carrier",
        },
        {
          field: "description",
          headerText: "Carrier Description",
        },
        {
          field: "truckType.name",
          headerText: "Truck Type",
        },
        {
          field: "email",
          headerText: "Email",
        },
        {
          field: "phoneNumber",
          headerText: "Phone Number",
        },
        {
          field: "integrationId",
          headerText: "Integration Id",
        },
        {
          headerText: "Action",
          template: this.carrierEditTemplate,
          width: 200,
        },
      ]
    );
  }

  addCarrierClicked(IsEdit: boolean, id?: number) {
    const carrierEditDialog = this.dialogService.open(
      CarrierEditListComponent,
      {
        width: "500px",
        data: {
          phone: this.dispatchPhone,
        },
      }
    );
    if (IsEdit) {
      this.editCarrier = {
        ...this.allCarriers.filter((u) => u.id === id)[0],
      };
      this.editTruck = {
        ...this.truckTypes.filter(
          (w) => w.id == this.editCarrier.truckTypeId
        )[0],
      };
      window.localStorage.setItem(
        "editCarrier" + this.editCarrier.name,
        JSON.stringify(this.editCarrier)
      );
      window.localStorage.setItem(
        "editTruck" + this.editTruck.name,
        JSON.stringify(this.editTruck)
      );
      this.sessionService.selectedCarrier = this.editCarrier;
      this.sessionService.selectedTruck = this.editTruck;
      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);
      this.editActive = true;
    }

    carrierEditDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.loadData();
      }
      // this.dispatchPhone = result;
      window.localStorage.removeItem("editCarrier" + this.editCarrier.name);
      window.localStorage.removeItem("editTruck" + this.editTruck.name);
      this.sessionService.selectedCarrier = {};
      this.sessionService.selectedTruck = {};
    });
  }

  editDispatch() {
    const confRef = this.dialogService
      .open(DispatchEditComponent, {
        minWidth: "10%",
        data: {
          phone: this.dispatchPhone,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if(result){
          this.dispatchPhone = result;
        }
      });
  }

  confirmDelete(id: number) {
    const confirmDialogData: ConfirmationData = {
      title: "Confirm Deletion",
      confirmMessage: "Are you sure you want to delete this carrier?",
      confirmLabel: "Delete",
      cancelLabel: "Cancel",
      displayComment: false,
      isWarning: false,
    };

    const confirmDialog = this.dialogService.open(ConfirmationDialogComponent, {
      width: "400px",
      data: confirmDialogData,
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteRec(id);
      }
    });
  }

  deleteRec(id: number) {
    this.carrierService.deleteCarrierDTO(id).subscribe(
      () => {
        this.toastrService.success("Carrier successfully deleted!");
        this.loadData();
      },
      (error) => {
        this.errorService.handleError(error);
        console.error(error);
      }
    );
  }

  clearFilters() {
    window.localStorage.setItem("gridCarrierConfigFilters", ""); // Here grid is component name and OrderDetails is component ID
    this.grid.clearFiltering();
  }

  telephone_click() {
    window.open("tel:" + this.dispatchPhone);
  }
}
