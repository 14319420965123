import { AfterViewInit, Component, ViewChild, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { ContractTableDataSource } from './contract-list-datasource';
// import { OrdersService } from '../../services/orders.service';
import { ErrorService } from '../../../../@core/utils/error.service';
// import { Brewery } from '../../../../brewery-configuration/models/brewery';
import { faClone, faTrash, faHourglassHalf, faHeadset, faTimes, faCheck, faSearch, faPrint, faWindowClose, faSave, faEdit, faBinoculars, faFileUpload, faUpload, faDemocrat, faEye } from '@fortawesome/free-solid-svg-icons';
// import { BreweryService } from '../../../../brewery-configuration/service/brewery.service';
// import { Order, OrderLine } from '../../models/order';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as lodash from 'lodash';
import { Router } from '@angular/router';
import { SessionService } from '../../../../@core/utils/session.service';
// import { ReportService } from '../../../../../../../reports/report.service';
import * as moment from 'moment';
import * as _ from 'lodash';
// import { BuildListService } from '../../services/build-list.service';
import { ExcelService } from '../../../../@core/utils/excel.service';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';
import { Observable } from 'rxjs';
import { faFlag } from '@fortawesome/pro-duotone-svg-icons';
// import { OrderPriorityFlagComponent } from '../../widgets/order-priority-flag/order-priority-flag.component';
import { AuthService } from '../../../../@core/auth/auth.service';
import { ContractDTO } from '../models/contract';
import { ContractLine } from '../models/lineItem';
import { ContractService } from '../services/contract.service';
import { PDFService } from '../services/pdf.service';
import { VarietyService } from '../../../../harvest-engine/core/services/variety.service';
// import { CropVariety } from '../../../core/models/crop-variety';
import { InventoryPackageTypeService } from '../../../../harvest-engine/modules/inventory/services/inventory-package-type.service';
// import { InventoryPackageTypeDTO } from '../../inventory/models/inventory-package-type';
import { InventoryService } from '../../../../harvest-engine/modules/pick-plan/services/inventory.service';
import { InventoryItemDTO } from '../../inventory/models/item';
import { PopupModalComponent } from '../widgets/popup-modal/popup-modal.component';
import { FileUploadService } from '../../../core/services/FileUpload.service';
import { Modal } from 'ngx-modialog/plugins/bootstrap';



@Component({
  selector: 'contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class ContractListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  expandedElement: ContractTableDataSource | null;
  dataSource: ContractTableDataSource;

  confirmationVisible = false;
  public faBinoculars = faBinoculars;
  public faHeadset = faHeadset;
  public faHourglassHalf = faHourglassHalf;
  public faTimes = faTimes;
  public faCheck = faCheck;
  public faSearch = faSearch;
  public faPrint = faPrint;
  public faWindowClose = faWindowClose;
  public faSave = faSave;
  public faEdit = faEdit;
  public faFileUpload = faFileUpload;
  public faUpload = faUpload;
  public faTrash = faTrash;
  faCalendarAlt = faCalendarAlt;
  faFlag = faFlag;
  faDemocrat = faDemocrat;
  faEye = faEye;
  faClone = faClone;
  rowConfirm: object = {};

  public allSelected: boolean = false;
  public salesReps: string[] = [];
  public allData: ContractDTO[] = [];
  public isLoading: boolean = false;
  public isDownloading: boolean = false;
  public searchCustomer: string = '';
  public searchContractType: string = '';
  public searchContractYear: string = '';
  public searchStatus: string = '';
  // public allVarieties: CropVariety[];
  // public allPackageTypes: InventoryPackageTypeDTO[];
  public allInventoryItems: InventoryItemDTO[];


  public searchStatuses: string[] = ['Pending', 'Preparing for Shipment', 'Flagged Orders'];

  public selectedPickListDate: Date = new Date();

  // public allBreweries: Brewery[] = [];
  public statuses: string[] = [];
  public selectedDate: string;
  public date: string = moment().format('2020-08-31T00:00:00.000Z');
  public files: File[] = [];

  constructor(
    // public orderService: OrdersService,
    public errorService: ErrorService,
    // public breweryService: BreweryService,
    private router: Router,
    public session: SessionService,
    // public reportService: ReportService,
    public excelService: ExcelService,
    // public buildListService: BuildListService,
    private matDialog: MatDialog,
    public authService: AuthService,
    public contractService: ContractService,
    public pdfService: PDFService,
    public varietyService: VarietyService,
    public inventoryPackageTypeService: InventoryPackageTypeService,
    public inventoryService: InventoryService,
    public fileUploadService: FileUploadService,
    private modal: Modal
  ) {
  }

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['customerName', 'contractType', 'contractYear', 'effectiveDate', 'expirationDate', 'status', 'salesRep', 'edit', 'upload', 'ammend', 'view', 'delete'];

  ngOnInit() {
    this.dataSource = new ContractTableDataSource(this.paginator, this.sort);

    this.getContracts();

  }

  config = {
    url: 'http://localhost:3000/upload',
    maxFiles: 1,
    acceptedFiles: 'image/jpeg, image/png, application/pdf'
  };

  // onFileUpload(event) {
  //   console.log(event);
  //   // Handle the uploaded file here
  // }
  onFileUpload(event) {
    // console.log(event.addedFiles);
    this.files.push(...event.addedFiles);
  }
  
  onRemove(event) {
    // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
  viewContract(row){
    this.fileUploadService.getContractFileUploads(row.id).subscribe((res) => {
      // console.log(res)
      if(res.length > 0){
        // console.log('Has file uploaded....')
        const byteCharacters = atob(res[0].fileByte);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new Blob([byteArray], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } else {
        this.pdfService.openPDF(JSON.parse(row.contractVerbiage), row.customerName)
      }
    })
  }
  showConfirmation(row) {
    this.rowConfirm = row;
    // this.confirmationVisible = true;
    let dialogRef = this.modal.confirm()
      .size('sm')
      .isBlocking(true)
      .showClose(false)
      .keyboard(27)
      .title('Confirmation')
      .body('Are you sure you want to delete this contract?')
      .okBtn('Yes')
      .okBtnClass('btn btn-danger')
      .cancelBtn('No')
      .cancelBtnClass('btn btn-secondary')
      .open();

    dialogRef.result
      .then((result) => {
        if (result) {
          // Confirmation was accepted
          this.deleteContract(this.rowConfirm);
          this.rowConfirm = {};
        } else {
          // Confirmation was canceled
          this.rowConfirm = {};
        }
      })
      .catch(() => {
        // Error handling
        this.rowConfirm = {};
      });
  }

  // confirm() {
  //   this.deleteContract(this.rowConfirm)
  //   this.confirmationVisible = false;
  // }

  // cancel() {
  //   // Code to handle cancellation
  //   this.rowConfirm = {};
  //   this.confirmationVisible = false;
  // }

  deleteContract(row){
    this.contractService.deleteContract(row.id).subscribe((res) => {
      this.getContracts();
    })
  }

  openFileUpload(row){
    // console.log(row)
    const confRef = this.matDialog.open(PopupModalComponent, {
      minWidth: '35%',
      data: {
        row: row,
      }
    }).afterClosed().subscribe(result => {
      this.getContracts();
    });
  }


  getContracts() {
    this.isLoading = true;

    Observable.forkJoin([
      this.contractService.getContracts(),
      // this.varietyService.getVarieties(),
      // this.inventoryPackageTypeService.getAll(),
      this.inventoryService.getInventoryItems()
    ]
    ).subscribe((data: any) => {
      // this.allVarieties = data[1];
      // this.allPackageTypes = data[2];
      this.allInventoryItems = data[1];
      data[0].forEach(d => {
        d.contractLine.forEach(l => {
          // l.varietyName = this.allVarieties.find(v => v.id == l.varietyId).name || "";
          // l.packageName = this.allPackageTypes.find(p => p.id == l.packageId).name || "";
          if (l.inventoryItemId) {
            const foundItem = this.allInventoryItems.find(i => i.id === l.inventoryItemId);
            l.inventoryItemName = foundItem ? foundItem.name : "";
          }
        })
      })

      this.dataSource.data = data[0];
      this.allData = data[0];
      // console.log(data[0])

      this.isLoading = false;
      this.paginator._changePageSize(this.paginator.pageSize);
      this.searchNoDate();

    }, err => this.errorService.handleError(err));
  }

  onSearch() {
    this.isLoading = true;
    // console.log(this.allData)
    this.dataSource.data.map(d => {
      // console.log(d.customerName)
    })
    this.dataSource.data = [...this.allData.filter(d =>
      (d.customerName || '').toLowerCase().includes(this.searchCustomer.toLowerCase())
      && (d.contractTemplateName || '').toLowerCase().includes(this.searchContractType.toLowerCase())
      && (d.contractYear || '').toString().toLowerCase().includes(this.searchContractYear.toLowerCase())
      && (d.status || '').toLowerCase().includes(this.searchStatus.toLowerCase())
    )];


    this.updateOrders();
    this.isLoading = false;
  }

  updateOrders() {
    this.paginator.firstPage();
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  downloadBuildList() {
   
  }

  getSubtotal(lines: ContractLine[]) {
    let total: number = 0;

    if (lines != null) {
      for (let x of lines) {
        if (x.price != undefined || x.price != null) {
          total = total + (x.price * x.quantity);
        }
      }
      return total;
    }
  }

  getTotalQty(lines: ContractLine[]) {
    let total: number = 0;
    if (lines != null) {
      for (let x of lines) {
        if (x.quantity != undefined || x.quantity != null) {
          total = total + x.quantity;
        }
      }
      return total;
    }
  }

  getTotalItems(lines: ContractLine[]) {
 
  }

  printPickList() {
    
  }

  navigateToContractEdit(contract: ContractDTO) {
    this.session.contract = contract;
    // console.log(contract)
    if(contract.contractTemplateName == 'Amendment'){
      this.router.navigateByUrl(`/harvest-engine/contracts/contract-ammend?contractId=${contract.id}&edit=true`);
    } else{
      this.router.navigateByUrl(`/harvest-engine/contracts/contract-input?contractId=${contract.id}`);
    }
  }

  goToContractAmmend(contract: ContractDTO) {
    this.session.contract = contract;
    this.router.navigateByUrl(`/harvest-engine/contracts/contract-ammend?contractId=${contract.id}&edit=false`);
  }

  searchNoDate() {

   
  }

  flagOrder(order: ContractLine) {
    // this.session.order = { ...order };
    // const confRef = this.matDialog.open(PopupModalComponent, {
    //   minWidth: '35%',
    //   data: {
    //     order: order,
    //   }
    // }).afterClosed().subscribe(result => {

      // order.flag = this.session.order.flag;
      // order.flagColor = this.session.order.flagColor;
      // order.flagComments = this.session.order.flagComments;

      // this.dataSource.data = _.sortBy(this.dataSource.data, [function (item) {
      //   return item.flag;
      // }, function (item) {
      //   return item.orderDate;
      // }]).reverse();

      // this.session.order = {};

      // this.updateOrders();
    // });
  }

  selectAllStatuses() {
  
  }

  onChange() {
    this.selectedPickListDate = moment(this.selectedDate).toDate();
  }
}

