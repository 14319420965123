import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faBan, faCheck, faPen, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { KwFilterDropdownService } from '../../../widgets/kw-filter-dropdown/kw-filter-dropdown.service';
import { CropVariety } from '../../../models/crop-variety';
import { VarietiesDetailComponent } from '../varieties-detail/varieties-detail.component';
import { ColumnModel, FilterSettingsModel, GridComponent, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { SessionService } from '../../../../../@core/utils/session.service';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { NbToastrService } from '@nebular/theme';
import { KmlService } from '../../../../../@core/utils/kml.service';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { VarietyService } from '../../../services/variety.service';
import { Observable } from 'rxjs';
import { ConfirmationData, ConfirmationDialogComponent } from '../../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';
import * as _ from 'underscore';
@Component({
  selector: 'kw-varieties-list',
  templateUrl: './varieties-list.component.html',
  styleUrls: ['./varieties-list.component.scss']
})
export class VarietiesListComponent implements OnInit {
  
  //#region Properties
  public cropLoading: boolean = true;
  public varietyList: CropVariety[] = [];
  public allVariety: CropVariety[] = [];
  public submitting: any;
  public allColumns: ColumnModel[] = [];
  public gridName: string = "varietiesConfigFilters";
  public pageSettings: PageSettingsModel = {
    pageSize: 50,
    pageSizes: [50, 100, 200],
  };
  public filterSettings: FilterSettingsModel = {
    mode: "Immediate",
    type: "Excel",
  };
  public toolbarOptions: string[] = [
    "ExcelExport",
    "KML Export",
    "JSON Export",
  ];
  public enablePersistence: boolean = true;

  displayedColumns = ['name','crop', 'code', 'integrationId', 'uiColor', 'active', 'edit'];
  //#endregion
  
  //#region Icon Properties
  public faPlus = faPlus;
  public faPen = faPen;
  public faTrash = faTrash;
  public faTimes = faTimes;
  public faCheck = faCheck;
  public faBan = faBan;
  //#endregion

  //#region ViewChild Properties

  @ViewChild("grid") grid: GridComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild("actionTemplate")
  public actionTemplate: any;

  @ViewChild("isActiveTemplate")
  public isActiveTemplate: any;

  @ViewChild("uiColorTemplate")
  public uiColorTemplate: any;
  //#endregion
 
  
  constructor(
    private sessionService: SessionService,
    public dialogService: MatDialog,
    private errorService: ErrorService,
    private kmlService: KmlService,
    private toastrService: NbToastrService,
    private vrtService: VarietyService
  ) { }

  ngOnInit() {
    this.cropLoading = true;
    
    this.vrtService.getCategories().subscribe(x => {
      if(x.length >0)
        this.displayedColumns = ['name','crop', 'code', 'integrationId','category' ,'uiColor', 'active', 'edit'];
    })
    this.vrtService.getVarieties(true).subscribe(vrts => {
       this.varietyList = [...vrts];
       if(this.paginator) this.paginator._changePageSize(this.paginator.pageSize);
    })
    this.loadData();
  }

  loadData() {
    this.cropLoading = true;

    Observable.forkJoin([
      this.vrtService.getVarieties(),
    ]).subscribe(
      (results) => {
        this.allVariety = results[0];

        this.cropLoading = false;
        this.setColumns();
      },
      (err) => {
        this.errorService.handleError(err);
        this.cropLoading = false;
      }
    );
  }

  toolbarClick(args) {
    this.cropLoading = true;
    this.grid.aggregates = [];
    this.grid.refresh();

    if (args.item.properties.text == "KML Export") {
      this.kmlService.getAllKML(new Date().getFullYear());
      this.cropLoading = false;
    } else if (args.item.properties.text == "JSON Export") {
      this.kmlService.getGeoJSONPolygons();
      this.cropLoading = false;
    } else {
      this.grid
        .excelExport({
          fileName: `VarietiesExport.xlsx`,
        })
        .then(() => {
          this.cropLoading = false;
        });
    }
  }

  setColumns() {
    this.allColumns = [];

    this.allColumns.push(
      ...[
        {
          field: "name",
          headerText: "Name",
        },
        {
          field: "varietyShortName",
          headerText: "Code",
        },
        {
          field: "categoryName",
          headerText: "Category",
        },
        {
          field: "integrationId",
          headerText: "Integration Id",
        },
        {
          field: "cropName",
          headerText: "Crop",
        },
        {
          field: "uiColor",
          headerText: "Color",
          template: this.uiColorTemplate
        },
        {
          field: "isActive",
          headerText: "Is Active",
          template: this.isActiveTemplate
        },
        {
          headerText: "Action",
          template: this.actionTemplate
        },
      ]
    );
  }

  confirmDelete(id: number) {
    const confirmDialogData: ConfirmationData = {
      title: "Confirm Deletion",
      confirmMessage: "Are you sure you want to delete this pool?",
      confirmLabel: "Delete",
      cancelLabel: "Cancel",
      displayComment: false,
      isWarning: false,
    };

    const confirmDialog = this.dialogService.open(ConfirmationDialogComponent, {
      width: "400px",
      data: confirmDialogData,
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteRec(id);
      }
    });
  }

  deleteRec(id: number) {
    this.cropLoading = true;
    this.vrtService.deleteCropVariety(id).subscribe(
      () => {
        this.toastrService.info("Verieties successfully deleted!");
        this.cropLoading = false;
        this.loadData();
      },
      (err) => {
        this.errorService.handleError(err);
        this.cropLoading = false;
      }
    );
  }

  openDialog(IsEdit: boolean, data?: CropVariety) {
    const varietiesDetailsDialog = this.dialogService.open(VarietiesDetailComponent, {
      width: "700px",
      data: {
        isEditBtnClick: IsEdit
      }
    });
    if (IsEdit) {
      var persistData = (this.grid as GridComponent).getPersistData();
      window.localStorage.setItem("grid" + this.gridName, persistData);
      this.sessionService.selectedcropVarieties = data;
    } else {
      window.localStorage.removeItem("grid" + this.gridName);
      this.sessionService.selectedcropVarieties = {} as CropVariety;
    }

    varietiesDetailsDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.loadData();
      }
      window.localStorage.removeItem("grid" + this.gridName);
      this.sessionService.selectedcropVarieties = {} as CropVariety;
    });
  }

  clearFilters() {
    window.localStorage.setItem("grid" + this.gridName, ""); // Here grid is component name and OrderDetails is component ID
    this.grid.clearFiltering();
  }

}
