import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { faBan, faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ErrorService } from '../../../../@core/utils/error.service';
import { Fieldman } from '../../models/fieldman';
import { FieldmanService } from '../../services/Fieldman.service';
import { FieldmanListDataSource } from './fieldman-list-datasource';
import { Observable } from 'rxjs';
import { ColumnModel, FilterSettingsModel, GridComponent, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { KmlService } from '../../../../@core/utils/kml.service';
import { NbToastrService } from '@nebular/theme';
import { SessionService } from '../../../../@core/utils/session.service';
import { FieldmanEditListComponent } from './fieldman-edit-list/fieldman-edit-list.component';
import { ConfirmationData, ConfirmationDialogComponent } from '../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'fieldman-list',
  templateUrl: './fieldman-list.component.html',
  styleUrls: ['./fieldman-list.component.css']
})
export class FieldmanListComponent implements OnInit {
  //#region Properties
  dataSource: FieldmanListDataSource;
  public editfieldman: Fieldman = {};
  public fieldmanId: number;
  public fieldmanLoading = false;
  public submitting: any;
  public editActive = false;
  public allFieldMan: Fieldman[] = [];
  public allColumns: ColumnModel[] = [];
  public gridName: string = "FieldmanConfigGrid";
  public pageSettings: PageSettingsModel = {
    pageSize: 50,
    pageSizes: [50, 100, 200],
  };
  public filterSettings: FilterSettingsModel = {
    mode: "Immediate",
    type: "Excel",
  };
  public toolbarOptions: string[] = [
    "ExcelExport",
    "KML Export",
    "JSON Export",
  ];
  public enablePersistence: boolean = true;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['firstName', 'lastName', 'email', 'phone', 'edit'];
  //#endregion

  //#region Icon Properties
  public faPlus = faPlus;
  public faPen = faPen;
  public faTrash = faTrash;
  public faBan = faBan;
  //#endregion

  //#region ViewChild Properties
  @ViewChild(MatPaginator) 
  paginator: MatPaginator;

  @ViewChild(MatSort) 
  sort: MatSort;

  @ViewChild("grid") 
  grid: GridComponent;

  @ViewChild("actionTemplate")
  public actionTemplate: any;

  @ViewChild("isActiveTemplate")
  public isActiveTemplate: any;
  //#endregion

  //#region Constructor with dependencies
  constructor(
    private fieldmanService: FieldmanService,
    private errorService: ErrorService,
    private kmlService: KmlService,
    private toastrService: NbToastrService,
    private sessionService: SessionService,
    public dialogService: MatDialog
  ) { }
  //#endregion
  
  ngOnInit() {
    this.dataSource = new FieldmanListDataSource(this.paginator, this.sort);
    this.fieldmanLoading = true;

    this.fieldmanService.getFieldmen().subscribe(data => {
      this.dataSource.data = data;
      if(this.paginator) this.paginator._changePageSize(this.paginator.pageSize);
      this.fieldmanLoading = false;
    }, err => { this.errorService.handleError(err); this.fieldmanLoading = false; });
    this.loadData();
  }

  loadData() {
    this.fieldmanLoading = true;

    Observable.forkJoin([
      this.fieldmanService.getFieldmen(),
    ]).subscribe(
      (results) => {
        this.allFieldMan = results[0];

        this.fieldmanLoading = false;
        this.setColumns();
      },
      (err) => {
        this.errorService.handleError(err);
        this.fieldmanLoading = false;
      }
    );
  }

  toolbarClick(args) {
    this.fieldmanLoading = true;
    this.grid.aggregates = [];
    this.grid.refresh();

    if (args.item.properties.text == "KML Export") {
      this.kmlService.getAllKML(new Date().getFullYear());
      this.fieldmanLoading = false;
    } else if (args.item.properties.text == "JSON Export") {
      this.kmlService.getGeoJSONPolygons();
      this.fieldmanLoading = false;
    } else {
      this.grid
        .excelExport({
          fileName: `FieldmanExport.xlsx`,
        })
        .then(() => {
          this.fieldmanLoading = false;
        });
    }
  }

  setColumns() {
    this.allColumns = [];

    this.allColumns.push(
      ...[
        {
          field: "firstName",
          headerText: "FirstName",
        },
        {
          field: "lastName",
          headerText: "LastName",
        },
        {
          field: "email",
          headerText: "Email",
        },
        {
          field: "phone",
          headerText: "Phone",
        },
        {
          headerText: "Action",
          template: this.actionTemplate,
          width: 200,
        },
      ]
    );
  }
  
  confirmDelete(id: number) {
    const confirmDialogData: ConfirmationData = {
      title: "Confirm Deletion",
      confirmMessage: "Are you sure you want to delete this field man?",
      confirmLabel: "Delete",
      cancelLabel: "Cancel",
      displayComment: false,
      isWarning: false,
    };

    const confirmDialog = this.dialogService.open(ConfirmationDialogComponent, {
      width: "400px",
      data: confirmDialogData,
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteRec(id);
      }
    });
  }

  deleteRec(id: number) {
    this.fieldmanLoading = true;
    this.fieldmanService.deleteFieldman(id).subscribe(
      () => {
        this.toastrService.info("Field man successfully deleted!");
        this.fieldmanLoading = false;
        this.loadData();
      },
      (err) => {
        this.errorService.handleError(err);
        this.fieldmanLoading = false;
      }
    );
  }

  openDialog(IsEdit: boolean, data?: Fieldman) {
    const fieldManDetailsDialog = this.dialogService.open(FieldmanEditListComponent, {
      width: "500px",
    });
    if (IsEdit) {
      var persistData = (this.grid as GridComponent).getPersistData();
      window.localStorage.setItem("grid" + this.gridName, persistData);
      this.sessionService.selectedFieldMan = data;
    } else {
      window.localStorage.removeItem("grid" + this.gridName);
      this.sessionService.selectedFieldMan = {} as Fieldman;
    }

    fieldManDetailsDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.loadData();
      }
      window.localStorage.removeItem("grid" + this.gridName);
      this.sessionService.selectedFieldMan = {} as Fieldman;
    });
  }

  clearFilters() {
    window.localStorage.setItem("gridFieldmanConfigFilters", ""); // Here grid is component name and OrderDetails is component ID
    this.grid.clearFiltering();
  }
}
