import { Injectable, ErrorHandler } from '@angular/core';
import {UNAUTHORIZED, BAD_REQUEST, FORBIDDEN, INTERNAL_SERVER_ERROR, NOT_FOUND} from 'http-status-codes';
import {Router} from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { NbToastrConfig } from '@nebular/theme/components/toastr/toastr-config';

@Injectable({
  providedIn: 'root'
})
export class ErrorService implements ErrorHandler {

  static readonly REFRESH_PAGE_ON_TOAST_CLICK_MESSAGE: string = 'An error occurred: Please click this message to refresh';
  static readonly DEFAULT_ERROR_TITLE: string = 'Error';
  static readonly DEFAULT_ERROR_MESSAGE: string = 'An internal server error has occured.  Please try again later.  If issue persists, please contact support@kragworks.com';
  
  public handleError(error: any): void {

    if(error.status === undefined || error.status === null){
      this.showError(ErrorService.DEFAULT_ERROR_MESSAGE);
    }

    const httpErrorCode = error.status;
    switch (httpErrorCode) {
      case UNAUTHORIZED:
          this.router.navigateByUrl('/login');
          break;
      case FORBIDDEN:
          // this.router.navigateByUrl('/unauthorized');
          this.showError('You are not authorized to perform this request.');
          break;
      case BAD_REQUEST:
         this.showError(error.message);
          break;
      case NOT_FOUND:
         this.showError("Resource not found "+error.message);
          break;
      case INTERNAL_SERVER_ERROR:
          if(error.error.message){
            this.showError(error.error.message);
          } else {
            this.showError(ErrorService.DEFAULT_ERROR_MESSAGE);
          }

          break;
      default:
         this.showError(ErrorService.DEFAULT_ERROR_MESSAGE);
    }
  }

    private showError(message: string) {
      
      // this.toastr.error(message, ErrorService.DEFAULT_ERROR_TITLE);
      this.toastr.danger(message, ErrorService.DEFAULT_ERROR_TITLE, <NbToastrConfig>{duration: 0});
    }

  constructor(
    private router: Router,
    private toastr: NbToastrService
  ) { }
}
