import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../../../../../@core/utils/session.service';
import { AuthService } from '../../../../../@core/auth/auth.service';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { NbToastrService } from '@nebular/theme';
import { FarmDTO } from '../../../models/farm';
import { FarmsService } from '../../../services/farms.service';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'farm-detail',
  templateUrl: './farm-detail.component.html',
  styleUrls: ['./farm-detail.component.scss']
})
export class FarmDetailComponent implements OnInit {

  public editFarm: FarmDTO;
  public loading: boolean = true;
  public submitting: boolean = false;
  private farmId: number;

  constructor(
    private route: ActivatedRoute,
    private session: SessionService,
    private farmService: FarmsService,
    private authService: AuthService,
    private errorService: ErrorService,
    private toastr: NbToastrService,
    private router: Router,
    public dialogRef: MatDialogRef<any>
  ) { }

  ngOnInit() {

    this.farmId = +this.route.snapshot.paramMap.get('id') || 0;

    // if the session has a selected farm and the id passed in matches, use that
    this.editFarm = this.session.selectedFarm;
    // else if we have an ID, but the session farm is empty (someone clicked refresh in the browser)
    if (this.farmId !== undefined && this.session.selectedFarm === undefined && this.farmId !== 0) {
      this.editFarm = {
        isActive: true
      };

      this.farmService.apiFarmsByIdGet(this.farmId).subscribe(f => {
        this.editFarm = f;
        this.loading = false;
      }, err => { this.errorService.handleError(err); });
    } else {
      if (this.editFarm === undefined) {
        this.editFarm = {};
        this.editFarm.isActive = true;;
      }
      this.loading = false;
    }

  }

  setFarmInactive() {
    this.editFarm.isActive = false;
    this.onSubmit();
  }

  public deleteClicked() {
  }

  public onSubmit() {
    this.submitting = true;
    setTimeout(() => {
      if (this.editFarm.id === null || this.editFarm.id === undefined || this.editFarm.id === 0) {
        this.farmService.apiFarmsPost(this.editFarm).subscribe(result => {
          this.toastr.success('Successfully created ' + this.editFarm.name);
          this.submitting = false;
          // this.router.navigateByUrl('harvest-engine/core/farms');
          this.dialogRef.close(true);
        }, error => {
          this.errorService.handleError(error);
          this.submitting = false;
          this.dialogRef.close(false);
        });
      } else {
        this.farmService.apiFarmsByIdPut(this.editFarm.id,
          this.editFarm
        ).subscribe(result => {
          this.toastr.success('Successfully updated ' + this.editFarm.name);
          this.submitting = false;
          // this.router.navigateByUrl('harvest-engine/core/farms');
          this.dialogRef.close(true);
        }, error => { 
          this.errorService.handleError(error);
          this.submitting = false;
          this.dialogRef.close(false);
        });
      }
    }, 2000)
  }

  closeDialog(cancelled: boolean = false) {
    this.dialogRef.close(cancelled);
  }
}
