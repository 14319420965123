import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { faCheck,
  faEye,
  faPen,
  faPlus,
  faPlusCircle,
  faTimes,
  faTrash,
  faBan } from '@fortawesome/free-solid-svg-icons';
import { ErrorService } from '../../../../@core/utils/error.service';
import { CropService } from '../../services/crop.service';
import { Crop } from '../../models/crop';
import { Observable } from "rxjs";
import {
  ColumnModel,
  FilterSettingsModel,
  GridComponent,
  PageSettingsModel,
} from "@syncfusion/ej2-angular-grids";
import {
  ConfirmationData,
  ConfirmationDialogComponent,
} from "../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component";
import { KmlService } from "../../../../@core/utils/kml.service";
import { NbToastrService } from "@nebular/theme";
import { SessionService } from "../../../../@core/utils/session.service";
import { CropDetailsComponent } from "./crop-details/crop-details.component";

// import { CropListDataSource } from './crop-list-datasource';
import * as _ from 'underscore';


@Component({
  selector: 'crop-list',
  templateUrl: './crop-list.component.html',
  styleUrls: ['./crop-list.component.css']
})
export class CropListComponent implements OnInit {

//#region Properties
public editActive = false;
public cropId: number;
public cropLoading = true;
public submitting: any;
public editCrop: Crop = {};
public faTimes = faTimes;
public faCheck = faCheck;
public allCrops: Crop[] = [];
public allColumns: ColumnModel[] = [];
public gridName: string = "CropConfigGrid";
public pageSettings: PageSettingsModel = {
  pageSize: 50,
  pageSizes: [50, 100, 200],
};
public filterSettings: FilterSettingsModel = {
  mode: "Immediate",
  type: "Excel",
};
public toolbarOptions: string[] = [
  "ExcelExport",
  "KML Export",
  "JSON Export",
];
public enablePersistence: boolean = true;
//#endregion

//#region Icon Properties
public faPlus = faPlus;
public faPen = faPen;
public faEye = faEye;
public faTrash = faTrash;
public faPlusCircle = faPlusCircle;
public faBan = faBan;
//#endregion

//#region ViewChild Properties
@ViewChild("grid") grid: GridComponent;

@ViewChild("actionTemplate")
public actionTemplate: any;

@ViewChild("isActiveTemplate")
public isActiveTemplate: any;
//#endregion

  constructor(
    private errorService: ErrorService,
    public cropService: CropService,
    private kmlService: KmlService,
    private toastrService: NbToastrService,
    private sessionService: SessionService,
    public dialogService: MatDialog
  ){}

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.cropLoading = true;

    Observable.forkJoin([
      this.cropService.getCrops(true),
    ]).subscribe(
      (results) => {
        this.allCrops= results[0];

        this.cropLoading = false;
        this.setColumns();
      },
      (err) => {
        this.errorService.handleError(err);
        this.cropLoading = false;
      }
    );
  }

  setColumns() {
    this.allColumns = [];

    this.allColumns.push(
      ...[
        {
          field: "name",
          headerText: "Crops Name",
        },
        {
          field: "seasonStartMonth",
          headerText: "Season Start Month",
        },
        {
          field: "seasonStartDay",
          headerText: "Season Start Day",
        },
        {
          headerText: "Action",
          template: this.actionTemplate,
          width: 200,
        },
      ]
    );
  }

  clearFilters() {
    window.localStorage.setItem("gridBlocksConfigFilters", "");
    this.grid.clearFiltering();
  }

  toolbarClick(args) {
    this.cropLoading = true;
    this.grid.aggregates = [];
    this.grid.refresh();

    if (args.item.properties.text == "KML Export") {
      this.kmlService.getAllKML(new Date().getFullYear());
      this.cropLoading = false;
    } else if (args.item.properties.text == "JSON Export") {
      this.kmlService.getGeoJSONPolygons();
      this.cropLoading = false;
    } else {
      this.grid
        .excelExport({
          fileName: `CropsExport.xlsx`,
        })
        .then(() => {
          this.cropLoading = false;
        });
    }
  }

  confirmDelete(id: number) {
    const confirmDialogData: ConfirmationData = {
      title: "Confirm Deletion",
      confirmMessage: "Are you sure you want to delete this crop?",
      confirmLabel: "Delete",
      cancelLabel: "Cancel",
      displayComment: false,
      isWarning: false,
    };

    const confirmDialog = this.dialogService.open(ConfirmationDialogComponent, {
      width: "400px",
      data: confirmDialogData,
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteRec(id);
      }
    });
  }

  deleteRec(id: number) {
    this.cropLoading = true;
    this.cropService.deleteCrop(id).subscribe(
      () => {
        this.toastrService.info("Crop successfully deleted!");
        this.cropLoading = false;
        this.loadData();
      },
      (err) => {
        this.errorService.handleError(err);
        this.cropLoading = false;
      }
    );
  }
  
  openDialog(IsEdit: boolean, data?: Crop) {
    const cropDetailsDialog = this.dialogService.open(CropDetailsComponent, {
      width: "500px",
    });
    if (IsEdit) {
      var persistData = (this.grid as GridComponent).getPersistData();
      window.localStorage.setItem("grid" + this.gridName, persistData);
      this.sessionService.selectedCrop = data;
    } else {
      window.localStorage.removeItem("grid" + this.gridName);
      this.sessionService.selectedCrop = {} as Crop;
    }

    cropDetailsDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.loadData();
      }
      window.localStorage.removeItem("grid" + this.gridName);
      this.sessionService.selectedCrop = {} as Crop;
    });
  }

}
