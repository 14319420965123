import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { faBan, faCheck, faPen, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ErrorService } from '../../../../@core/utils/error.service';
import { PoolTypeDTO } from '../../../modules/pick-plan/models/pool-type-dto';
import { PoolService } from '../../services/pool.service';
import { PoolListDataSource } from './pool-list-datasource';
import * as _ from 'underscore';
import { ColumnModel, FilterSettingsModel, GridComponent, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { KmlService } from '../../../../@core/utils/kml.service';
import { NbToastrService } from '@nebular/theme';
import { SessionService } from '../../../../@core/utils/session.service';
import { Observable } from 'rxjs';
import { ConfirmationData, ConfirmationDialogComponent } from '../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';
import { PoolDetailsComponent } from './pool-details/pool-details.component';

@Component({
  selector: 'pool-list',
  templateUrl: './pool-list.component.html',
  styleUrls: ['./pool-list.component.css']
})
export class PoolListComponent implements OnInit {

  //#region Properties
  dataSource: PoolListDataSource;
  public editPool: PoolTypeDTO = {};
  public allPooType: PoolTypeDTO[] = [];
  public poolId: number;
  public poolLoading: boolean = false;
  public submitting: any;
  public editActive = false;
  public allColumns: ColumnModel[] = [];
  public gridName: string = "PoolConfigGrid";
  public pageSettings: PageSettingsModel = {
    pageSize: 50,
    pageSizes: [50, 100, 200],
  };
  public filterSettings: FilterSettingsModel = {
    mode: "Immediate",
    type: "Excel",
  };
  public toolbarOptions: string[] = [
    "ExcelExport",
    "KML Export",
    "JSON Export",
  ];
  public enablePersistence: boolean = true;

   /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
   displayedColumns = ['name', 'description', 'integrationId', 'isActive', 'edit'];
   //#endregion
  
  //#region Icon Properties
  public faPlus = faPlus;
  public faPen = faPen;
  public faTrash = faTrash;
  public faTimes = faTimes;
  public faCheck = faCheck;
  public faBan = faBan;
  //#endregion

  //#region ViewChild Properties
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild("grid") 
  grid: GridComponent;

  @ViewChild("actionTemplate")
  public actionTemplate: any;

  @ViewChild("isActiveTemplate")
  public isActiveTemplate: any;
  //#endregion

  constructor(
    private poolService: PoolService,
    private errorService: ErrorService,
    private kmlService: KmlService,
    private toastrService: NbToastrService,
    private sessionService: SessionService,
    public dialogService: MatDialog
  ){}

  ngOnInit() {
    this.poolLoading = true;
    this.dataSource = new PoolListDataSource(this.paginator, this.sort);

    this.poolService.getPoolTypes().subscribe(data => {
      data = _.sortBy(data, 'name');
      this.dataSource.data = data;
      
      if(this.paginator) this.paginator._changePageSize(this.paginator.pageSize);
      this.poolLoading = false;
    }, err => {this.errorService.handleError(err); this.poolLoading = false;});
    this.loadData();
  }

  loadData() {
    this.poolLoading = true;

    Observable.forkJoin([
      this.poolService.getPoolTypes(),
    ]).subscribe(
      (results) => {
        this.allPooType = results[0];

        this.poolLoading = false;
        this.setColumns();
      },
      (err) => {
        this.errorService.handleError(err);
        this.poolLoading = false;
      }
    );
  }

  toolbarClick(args) {
    this.poolLoading = true;
    this.grid.aggregates = [];
    this.grid.refresh();

    if (args.item.properties.text == "KML Export") {
      this.kmlService.getAllKML(new Date().getFullYear());
      this.poolLoading = false;
    } else if (args.item.properties.text == "JSON Export") {
      this.kmlService.getGeoJSONPolygons();
      this.poolLoading = false;
    } else {
      this.grid
        .excelExport({
          fileName: `PoolExport.xlsx`,
        })
        .then(() => {
          this.poolLoading = false;
        });
    }
  }

  setColumns() {
    this.allColumns = [];

    this.allColumns.push(
      ...[
        {
          field: "name",
          headerText: "Name",
        },
        {
          field: "description",
          headerText: "Description",
        },
        {
          field: "integrationId",
          headerText: "Integration Id",
        },
        {
          headerText: "Action",
          template: this.actionTemplate,
          width: 200,
        },
      ]
    );
  }

  confirmDelete(id: number) {
    const confirmDialogData: ConfirmationData = {
      title: "Confirm Deletion",
      confirmMessage: "Are you sure you want to delete this pool?",
      confirmLabel: "Delete",
      cancelLabel: "Cancel",
      displayComment: false,
      isWarning: false,
    };

    const confirmDialog = this.dialogService.open(ConfirmationDialogComponent, {
      width: "400px",
      data: confirmDialogData,
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteRec(id);
      }
    });
  }

  deleteRec(id: number) {
    this.poolLoading = true;
    this.poolService.deletePoolTypeDTO(id).subscribe(
      () => {
        this.toastrService.info("Pool type successfully deleted!");
        this.poolLoading = false;
        this.loadData();
      },
      (err) => {
        this.errorService.handleError(err);
        this.poolLoading = false;
      }
    );
  }

  openDialog(IsEdit: boolean, data?: PoolTypeDTO) {
    const poolTypeDetailsDialog = this.dialogService.open(PoolDetailsComponent, {
      width: "500px",
    });
    if (IsEdit) {
      var persistData = (this.grid as GridComponent).getPersistData();
      window.localStorage.setItem("grid" + this.gridName, persistData);
      this.sessionService.selectedPoolType = data;
    } else {
      window.localStorage.removeItem("grid" + this.gridName);
      this.sessionService.selectedPoolType = {} as PoolTypeDTO;
    }

    poolTypeDetailsDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.loadData();
      }
      window.localStorage.removeItem("grid" + this.gridName);
      this.sessionService.selectedPoolType = {} as PoolTypeDTO;
    });
  }

  clearFilters() {
    window.localStorage.setItem("gridPoolConfigFilters", ""); // Here grid is component name and OrderDetails is component ID
    this.grid.clearFiltering();
  }
}
